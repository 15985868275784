<template>
  <div v-if="currentTherapy">
    <h5 v-if="!isMobile" class="text-dacy">{{currentTherapy.code}}</h5>
    <div class="mb-3">{{currentTherapy.title}}</div>
    <p class="text-danger" v-if="currentTherapy.special"><i class="el-icon-warning"></i> {{currentTherapy.special}}</p>

    <div v-if="currentTherapy.therapyProtocols && currentTherapy.therapyProtocols.length > 0">

      <h5 class="mt-4">Protokolle / Therapien</h5>
      <el-collapse class="border-top-0">
        <el-collapse-item :name="index" :key="therapyProtocol.protocol.id" v-for="(therapyProtocol, index) in currentTherapy.therapyProtocols">
          <template #title>
            <span style="word-break: break-all; line-height: normal;">{{(therapyProtocol.meta && therapyProtocol.meta.protocolown ? therapyProtocol.meta.protocolown.title : therapyProtocol.protocol.title)}}</span>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="offlabel"
              placement="top"
              v-if="therapyProtocol.inlabel == 0"
            >
              <el-tag class="ml-1" size="mini" type="danger">off</el-tag>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="inlabel"
              placement="top"
              v-if="therapyProtocol.inlabel == 1"
            >
              <el-tag class="ml-1" size="mini" type="success">in</el-tag>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="Experimentell"
              placement="top"
              v-if="therapyProtocol.inlabel == 2"
            >
              <el-tag class="ml-1" size="mini" type="warning">ex</el-tag>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="Zulassung erloschen"
              placement="top"
              v-if="therapyProtocol.inlabel == 3"
            >
              <el-tag class="ml-1" style="text-decoration:line-through;" size="mini" type="danger">in</el-tag>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="Offlabel durch G-BA erlaubt"
              placement="top"
              v-if="therapyProtocol.inlabel == 5"
            >
              <i class="ml-1 text-danger el-icon el-icon-circle-close"></i>
            </el-tooltip>
            <el-tooltip
              v-if="therapyNotInGER(therapyProtocol)"
              class="box-item"
              effect="dark"
              content="In Deutschland nicht verfügbar"
              placement="top"
            >
              <el-tag  class="ml-1" size="mini" type="danger" style="text-decoration: line-through;">BRD</el-tag>
            </el-tooltip>

            <el-tooltip v-if="therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0" placement="right">
              <template #content>
                <div :key="survey.id" v-for="survey of surveysOfTherapyProtocol(therapyProtocol)">{{survey.title_short}}</div>
              </template>
              <el-tag class="ml-1" size="mini">S</el-tag>
            </el-tooltip>

          </template>

          <div v-if="therapyProtocol.meta.protocolown"><h6>Protokollname DACY</h6>{{therapyProtocol.protocol.title}}</div>
          <div v-else-if="therapyProtocol.protocol.kz_title"><h6>Protokollkürzel</h6>{{therapyProtocol.protocol.kz_title}}</div>

          <div v-if="therapyProtocol.inlabel == 1">inlabel</div>
          <div v-if="therapyProtocol.inlabel == 0">offlabel</div>
          <div class="mt-3" v-if="therapyProtocol.specifics">
            <h6>Besonderheiten</h6>
            {{therapyProtocol.specifics}}
          </div>
          <div class="mt-3" v-if="therapyProtocol.duration">
            <h6>Dauer</h6>
            {{therapyProtocol.duration}}
          </div>
          <div v-if="therapyProtocol.protocol.drugs" class="mb-3 mt-3">
            <h6>Fachinformationen</h6>
            <div v-for="drug in therapyProtocol.protocol.drugs" :key="drug.id">
              <span v-if="drug.pdf_link"><a href="javascript:void(0)" @click="openBrowser(drug.pdf_link)">{{drug.title}}</a></span>
              <span v-else>{{drug.title}}</span>
              <el-tooltip
                v-if="!drug.available"
                class="box-item"
                placement="top"
                content="In Deutschland nicht verfügbar"
                effect="dark"
              >
                <el-tag  class="ml-1" size="mini" type="danger" style="text-decoration: line-through;">BRD</el-tag>
              </el-tooltip>
            </div>
          </div>
          <div v-if="therapyProtocol.protocol.description" style="white-space: pre-wrap; word-break: break-all"><h6>Beschreibung</h6>{{therapyProtocol.protocol.description}}</div>
          <div v-if="therapyProtocol.protocol.special" style="white-space: pre-wrap;"><h6>Besonderheiten</h6>{{therapyProtocol.protocol.special}}</div>
          <div v-if="therapyProtocol.protocol.oral"><h6>Oral</h6>{{therapyProtocol.protocol.oral}}</div>

          <div v-if="showTherapyProtocolStudyResults && therapyProtocol.studyresults && therapyProtocol.studyresults.length > 0">
            <h6 class="mt-2">Studienergebnisse</h6>
            <el-collapse :model-value="0" accordion class="border-top-0 border-bottom-0">
              <el-collapse-item :name="studyindex" :title="studyResult.study.title" :key="studyResult.id" v-for="(studyResult, studyindex) in therapyProtocol.studyresults">
                <h6 v-if="studyResult.pro_con == 1">Endpunkte</h6>
                <h6 v-if="studyResult.pro_con == 2">Einschränkungen</h6>
                <study-result-description :description="studyResult.description" :literature="studyResult.study.literature"></study-result-description>
                <h6 v-if="studyResult.study.literature.length" class="mt-2">Literatur</h6>
                <p :key="literature.id" v-for="literature in studyResult.study.literature">
                  <span v-if="literature.link_pubmed"><a href="javascript:void(0)" @click="openBrowser(literature.link_pubmed)">{{literature.signature}}</a></span>
                  <span v-else>{{literature.signature}}</span>
                </p>
                <h6 class="mt-4" v-if="studyResult.study.ECOG.length">ECOG</h6>
                <span class="mr-3" :key="ECOG.id" v-for="ECOG in studyResult.study.ECOG">
                          <el-tooltip placement="bottom">
                            <template #content><span v-if="ECOG.ecog_percent">{{ECOG.ecog_percent}}<br/></span>{{ ECOG.meta.description }}</template>
                            <el-tag>{{ECOG.meta.ecog}}</el-tag>
                          </el-tooltip>

                        </span>
              </el-collapse-item>
            </el-collapse>
          </div>

        </el-collapse-item>
      </el-collapse>

    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import StudyResultDescription from "./StudyResultDescription.vue";
import { therapyNotInGER, openBrowser, surveysOfTherapyProtocol } from "../../lib/reusable";

export default defineComponent({
  name: "Therapy",
  props: ['isMobile', 'currentTherapy', 'showTherapyProtocolStudyResults'],
  components: {StudyResultDescription},
  methods: {
    therapyNotInGER,
    openBrowser,
    surveysOfTherapyProtocol
  }
})
</script>
<style scoped>

</style>
